'use client'

import { useState, useEffect, useRef } from 'react'

export interface VideoBackgroundProps {
  src: string
  mobileSrc?: string
  poster?: string
  overlayOpacity?: number
  mobileBreakpoint?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
}

export function VideoBackground({
  src,
  mobileSrc,
  poster,
  overlayOpacity = 30,
  mobileBreakpoint = 'md',
}: VideoBackgroundProps) {
  const [isClient, setIsClient] = useState(false)
  const [selectedSource, setSelectedSource] = useState<string | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)

  // Convert Tailwind breakpoint to pixels
  const getBreakpointValue = (
    breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  ): number => {
    const breakpoints = {
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
      '2xl': 1536,
    }
    return breakpoints[breakpoint]
  }

  // Choose the appropriate video source based on screen width
  const selectVideoSource = () => {
    if (typeof window === 'undefined') return

    const breakpointValue = getBreakpointValue(mobileBreakpoint)
    const isMobile = window.innerWidth <= breakpointValue

    // Select the mobile source if it exists and screen is small, otherwise use desktop source
    const newSource = isMobile && mobileSrc ? mobileSrc : src

    if (newSource !== selectedSource) {
      setSelectedSource(newSource)
      console.info(
        `Selected ${isMobile ? 'mobile' : 'desktop'} video source at width ${window.innerWidth}px`
      )
    }
  }

  // Initialize on client side
  useEffect(() => {
    setIsClient(true)
    selectVideoSource()

    // Handle window resize
    const handleResize = () => {
      selectVideoSource()
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [src, mobileSrc, mobileBreakpoint])

  // Update video when source changes
  useEffect(() => {
    if (videoRef.current && selectedSource) {
      videoRef.current.src = selectedSource
      videoRef.current.load()
    }
  }, [selectedSource])

  // Simple error handler
  const onVideoError = () => {
    console.error('Video loading error', {
      source: selectedSource,
    })
  }

  const getOverlayOpacity = (opacity: number) => {
    // Log the received opacity value for debugging
    if (process.env.NODE_ENV === 'development') {
      console.info(
        `Overlay opacity prop: ${opacity}, calculated: ${opacity / 100}`
      )
    }
    return opacity / 100
  }

  return (
    <>
      {/* Gradient overlay for text readability */}
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-transparent to-transparent z-20" />

      {/* Main black overlay with configurable opacity */}
      <div
        className="absolute inset-0 bg-black z-10"
        style={{ opacity: getOverlayOpacity(overlayOpacity) }}
        data-opacity-value={overlayOpacity}
      />

      {/* Subtle fixed overlay */}
      <div className="absolute inset-0 bg-black/30 z-0" />

      {isClient ? (
        <video
          ref={videoRef}
          autoPlay
          muted
          loop
          playsInline
          poster={poster}
          onError={onVideoError}
          className="absolute inset-0 w-full h-full object-cover"
          controls={process.env.NODE_ENV === 'development'}
        />
      ) : (
        <div
          style={{
            backgroundImage: poster ? `url(${poster})` : undefined,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          className="absolute inset-0"
        />
      )}
    </>
  )
}
