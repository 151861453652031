'use client'

import { Button } from '@/components/ui/button'
import { SignedIn, SignedOut, SignInButton } from '@clerk/nextjs'

export function AuthButton() {
  return (
    <Button>
      <SignedIn>
        <a href="/onboarding">Get Started</a>
      </SignedIn>
      <SignedOut>
        <SignInButton>
          <a href="/onboarding">Get Started</a>
        </SignInButton>
      </SignedOut>
    </Button>
  )
}
