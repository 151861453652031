'use client'

import { FileText, BarChart3, HeadsetIcon } from 'lucide-react'
import { DemoCard } from './democard'

export function ProductDemoSection() {
  return (
    <section
      className="py-8 bg-white border border-gray-100 rounded-lg"
      id="features"
    >
      {/* Using light theme colors explicitly */}
      <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">
        How it Works
      </h2>
      <div className="grid gap-8 md:grid-cols-3 px-4">
        <DemoCard
          icon={<HeadsetIcon className="w-10 h-10 text-blue-600" />}
          title="Onboard your AI receptionist"
          description="Sign in, subscribe, and give verbal instructions for your dedicated, 24/7 AI receptionist. No typing, just talk in your own language."
          steps={[]}
          imageSrc="/moshizen-operators.jpg?height=200&width=300"
          imageAlt="Moshi Zen sign-up process screenshot"
        />
        <DemoCard
          icon={<FileText className="w-10 h-10 text-blue-600" />}
          title="Full Call Transcripts"
          description="Never miss the backstory with full transcripts of all your calls, including ones that were not passed through to you."
          steps={[]}
          imageSrc="/moshizen-transcripts.png?height=200&width=300"
          imageAlt="Transcripts at your disposal"
        />
        <DemoCard
          icon={<BarChart3 className="w-10 h-10 text-blue-600" />}
          title="Performance Analytics"
          description="Monitor your AI receptionist's performance, give feedback and change instructions on the fly - they are not complaining!"
          steps={[]}
          imageSrc="/moshizen-dashboard.png?height=200&width=300"
          imageAlt="TidyCalls sign-up process screenshot"
        />
      </div>
    </section>
  )
}
