import React from 'react'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import Image from 'next/image'

interface DemoCardProps {
  icon: React.ReactNode
  title: string
  description: string
  steps: string[]
  imageSrc: string
  imageAlt: string
}

export function DemoCard({
  icon,
  title,
  description,
  steps,
  imageSrc,
  imageAlt,
}: DemoCardProps) {
  return (
    <Card className="flex flex-col h-full bg-white border">
      <CardHeader>
        <div className="flex items-center space-x-4 mb-4">
          <div className="flex-shrink-0">{icon}</div>
          <CardTitle className="text-xl text-gray-900">{title}</CardTitle>
        </div>
        <CardDescription className="text-gray-600">
          {description}
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col">
        <div
          className="mb-4 relative h-[200px] rounded-md overflow-hidden bg-gray-100"
          suppressHydrationWarning
        >
          <Image
            src={imageSrc}
            alt={imageAlt}
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            className="rounded-md object-cover"
            loading="lazy"
            suppressHydrationWarning
          />
        </div>
        <ol className="list-decimal list-inside space-y-2 text-sm text-gray-600">
          {steps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ol>
      </CardContent>
    </Card>
  )
}
