'use client'

import { useState, useEffect, useRef, useCallback, memo } from 'react'
import { useInView } from 'react-intersection-observer'
import Link from 'next/link'
import Image from 'next/image'
import { Button } from '../ui/button'

// Remove all TypeScript declarations for requestIdleCallback
interface SelfHostedVideoProps {
  src: string
  title?: string
  content?: string
  poster?: string
  cta?: string
  ctaLink?: string
  autoPlay?: boolean
  muted?: boolean
  loop?: boolean
  controls?: boolean
  className?: string
  lazyLoadThreshold?: number
  isBelowFold?: boolean // New prop to explicitly control positioning
}

// Main component implementation - memoized to prevent unnecessary re-renders
const SelfHostedVideoImpl = memo(function SelfHostedVideoImpl({
  src,
  title = '',
  content = '',
  poster = '/images/promo-video-cover.jpg',
  cta = '',
  ctaLink = '',
  autoPlay = false,
  muted = true,
  loop = false,
  controls = true,
  className = '',
  lazyLoadThreshold = 200,
}: SelfHostedVideoProps) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isPlaying, setIsPlaying] = useState(autoPlay)
  const [error, setError] = useState<string | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isMuted, setIsMuted] = useState(muted)
  const [manualPause, setManualPause] = useState(false)

  // Use react-intersection-observer hook for better performance
  const { ref: containerRef, inView } = useInView({
    triggerOnce: false,
    rootMargin: `${lazyLoadThreshold}px`,
    threshold: 0.01,
  })

  // Set video source when in view - optimized with useCallback
  const handleInView = useCallback(() => {
    if (inView && videoRef.current) {
      if (!videoRef.current.src || !videoRef.current.src.includes(src)) {
        videoRef.current.src = src
      }
    }
  }, [inView, src])

  // Handle autoplay when element comes into view - optimized with useCallback
  const handleAutoplay = useCallback(() => {
    if (inView && autoPlay && videoRef.current && !manualPause) {
      // Use requestAnimationFrame to defer play until after paint
      requestAnimationFrame(() => {
        videoRef.current?.play().catch(() => {
          // Silent catch - browser policy may prevent autoplay
        })
      })
    }
  }, [inView, autoPlay, manualPause])

  // Event handlers - optimized with useCallback
  const handleLoaded = useCallback(() => {
    setIsLoaded(true)
    // Try to play immediately when loaded if autoplay is enabled and not manually paused
    if (autoPlay && videoRef.current && !manualPause) {
      videoRef.current.play().catch(() => {
        // Silent catch - browser policy may prevent autoplay
      })
    }
  }, [autoPlay, manualPause])

  const handlePlay = useCallback(() => setIsPlaying(true), [])
  const handlePause = useCallback(() => setIsPlaying(false), [])
  const handleError = useCallback(
    () => setError('Failed to load video. Please try again later.'),
    []
  )

  const handleRetry = useCallback(() => {
    setError(null)
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [])

  // Effects combined to reduce number of hooks and improve performance
  useEffect(() => {
    // Set video source when in view
    handleInView()

    // Handle autoplay
    handleAutoplay()
  }, [handleInView, handleAutoplay])

  // Setup event listeners - only runs on mount
  useEffect(() => {
    const video = videoRef.current
    if (!video) return

    video.addEventListener('loadeddata', handleLoaded)
    video.addEventListener('play', handlePlay)
    video.addEventListener('pause', handlePause)
    video.addEventListener('error', handleError)

    // Try to play immediately if autoplay is enabled
    if (autoPlay && video.readyState >= 2) {
      video.play().catch(() => {
        // Silent catch - browser policy may prevent autoplay
      })
    }

    return () => {
      video.removeEventListener('loadeddata', handleLoaded)
      video.removeEventListener('play', handlePlay)
      video.removeEventListener('pause', handlePause)
      video.removeEventListener('error', handleError)
    }
  }, [handleLoaded, handlePlay, handlePause, handleError, autoPlay])

  // Preload strategy - computed once per render cycle
  const preloadValue = inView ? 'auto' : 'none'
  const showLoading = !isLoaded && !error

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted
    }
  }, [isMuted])

  const toggleMute = () => {
    setIsMuted(!isMuted)
  }

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
        setManualPause(true)
      } else {
        videoRef.current.play().catch(() => {
          // Silent catch - browser policy may prevent autoplay
        })
        setManualPause(false)
      }
    }
  }

  // Reset manual pause when video comes back into view
  useEffect(() => {
    if (inView) {
      setManualPause(false)
    }
  }, [inView])

  return (
    <>
      {/* Aspect ratio container to prevent layout shift */}
      <div
        ref={containerRef}
        className="relative w-full pb-[56.25%] rounded-lg overflow-hidden shadow-lg bg-gray-100 dark:bg-gray-800"
        style={{ minHeight: '240px' }}
      >
        {showLoading && (
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-12 h-12 border-4 border-primary border-t-transparent rounded-full animate-spin"></div>
          </div>
        )}

        {error && (
          <div className="absolute inset-0 flex items-center justify-center flex-col p-4">
            <p className="text-red-500 mb-2">{error}</p>
            <button
              onClick={handleRetry}
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors"
            >
              Retry
            </button>
          </div>
        )}

        <video
          ref={videoRef}
          className={`absolute inset-0 w-full h-full ${!isLoaded && !error ? 'opacity-0' : 'opacity-100'} transition-opacity duration-300 ${className}`}
          poster={poster}
          controls={controls}
          autoPlay={false}
          muted={isMuted}
          loop={loop}
          playsInline
          preload={preloadValue}
          aria-label={title}
          style={{ objectFit: 'cover' }}
        >
          {/* Only render the source when in view to prevent browser warnings */}
          {inView && <source src={src} type="video/mp4" />}
          Your browser does not support the video tag.
        </video>
      </div>

      {content && (
        <div className="mt-4 text-center text-lg text-gray-500 dark:text-gray-400">
          {content}
        </div>
      )}
      {cta && ctaLink && (
        <div className="mt-4 text-center text-lg text-gray-500 dark:text-gray-400">
          <Link href={ctaLink}>
            <Button>{cta}</Button>
          </Link>
        </div>
      )}
    </>
  )
})

// Export a wrapper that ensures the video is below the fold and loads after LCP
export function SelfHostedVideo(props: SelfHostedVideoProps) {
  const [shouldRender, setShouldRender] = useState(false)

  // Use intersection observer to determine if the component is in view
  const { ref: placeholderRef, inView: placeholderInView } = useInView({
    triggerOnce: true,
    // Adjust this to trigger slightly before the element is in view for smoother experience
    rootMargin: '200px 0px',
  })

  useEffect(() => {
    // Only start rendering the actual video component when placeholder is in view
    if (placeholderInView) {
      setShouldRender(true)
    }
  }, [placeholderInView])

  // Use requestIdleCallback as a fallback after a minimum delay to ensure content eventually loads
  useEffect(() => {
    // Backup timer to ensure the video eventually loads even if not scrolled to
    const timer = setTimeout(() => {
      if (!shouldRender) {
        // Use requestIdleCallback if available to schedule non-critical work
        if ('requestIdleCallback' in window) {
          ;(window as any).requestIdleCallback(
            () => {
              setShouldRender(true)
            },
            { timeout: 2000 }
          )
        } else {
          setShouldRender(true)
        }
      }
    }, 5000) // Wait 5 seconds after initial load before considering loading the video

    return () => clearTimeout(timer)
  }, [shouldRender])

  // Wrapper component with container layout
  return (
    <div className="container mx-auto px-4 py-12">
      <h2 className="text-3xl font-bold text-center mb-6">{props.title}</h2>
      <div className="max-w-4xl mx-auto" ref={placeholderRef}>
        {!shouldRender ? (
          // Initial placeholder with proper aspect ratio to avoid layout shift
          <div
            className="relative w-full pb-[56.25%] rounded-lg overflow-hidden shadow-lg bg-gray-100 dark:bg-gray-800"
            style={{ minHeight: '240px' }}
          >
            {props.poster && (
              <>
                {/* Next/Image for better performance with the poster */}
                <div className="absolute inset-0">
                  {typeof props.poster === 'string' && (
                    <Image
                      src={props.poster}
                      alt={props.title || 'Video placeholder'}
                      fill
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                      loading="lazy"
                      className="object-cover"
                    />
                  )}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="bg-black bg-opacity-40 rounded-full p-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="white"
                        className="w-12 h-12"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          // Render the actual video component when needed
          <SelfHostedVideoImpl {...props} />
        )}

        {!shouldRender && props.content && (
          <div className="mt-4 text-center text-lg text-gray-500 dark:text-gray-400">
            {props.content}
          </div>
        )}

        {!shouldRender && props.cta && props.ctaLink && (
          <div className="mt-4 text-center text-lg text-gray-500 dark:text-gray-400">
            <Link href={props.ctaLink}>
              <Button>{props.cta}</Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}
